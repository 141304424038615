.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#dropdownContainer{
  display:flex;
  align-items: center;
  justify-content: center;
}
.flag{
  height: 30px;
  margin-right: 5px;
}
.dropdown-menu{
  background: #623686 !important;
} .dropdown-menu>a {
    color: #f1f1f1;
  } .dropdown-menu>a:hover {
        background: #623686;
        color: #f1f1f1;
      }
    .dropdown-item.active {
      background-color: #623686 !important;
      color: #f1f1f1;
    }

.home-section , .about-section, .project-section, .resume-section {
  flex-grow: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (min-width: 768px) {
  .navbar-collapse{
    justify-content: end;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#cvDownload{
  max-width: 12rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
